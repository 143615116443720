/**
* @author 阿佑[ayooooo@petalmail.com]
* @date 2022/4/29 15:32
* @description
*   ToggleFollowDialog.vue of WeTrade
*/
<template>
<DialogTemplate>
  <template #title>
    <t path="cup_23">Confirmation</t>
  </template>
  <t class="t-center" :path="isFollow?'cup_26':'cup_24'" :args="{trader: masterName}">confirm (un)follow?</t>
  <template #confirm>
    <Button class="flex-1 btn block primary" :progress="progress" @click="toggle">
      <t path="withdrawal_31" #="{td}" custom>{{td || 'Confirm'}}</t>
    </Button>
  </template>
</DialogTemplate>
</template>

<script lang="ts">
import Button from '@/components/Button.vue'
import { follow, unfollow } from '@/modules/weTradeCup/weTradeCup.api'
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'ToggleFollowDialog',
  components: { Button, DialogTemplate },
  props: {
    isFollow: Boolean,
    masterName: {
      type: String,
      required: true,
    },
    masterId: {
      type: Number,
      required: true,
    },
  },
  setup (props, ctx) {
    const progress = shallowRef(false)

    const toggle = () => {
      progress.value = true
      ;(props.isFollow ? unfollow : follow)({ masterId: props.masterId })
        .then(() => {
          ctx.emit('confirm')
          ctx.emit('close')
        })
        .finally(() => {
          progress.value = false
        })
    }

    return {
      toggle,
      progress,
    }
  },
})
</script>
