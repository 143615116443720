/**
* @author 阿佑[ayooooo@petalmail.com]
* @date 2022/4/27 10:51
* @description
*   WeTradeCup.index.vue of WeTrade
*/
<template>
<PageWithHeader>
  <template #title>
    <t path="cup_2">WeTrade Cup</t>
  </template>

  <template #right>
    <RouterLink to="/cup/wt/my">
      <t class="t-r f-md" path="cup_3">My Prize</t>
    </RouterLink>
  </template>

  <template #body>
    <div class="flex-1 wt-cup scroll-y">
      <div class="time df-col df-middle" style="padding-top: 64px">
        <div class="season">
          <t path="cup_4"></t>
        </div>
        <div class="date df-middle mt-6">
          <div class="date-l"></div>
          <t class="date-m" path="cup_5">April 1- June 30</t>
          <div class="date-r"></div>
        </div>
      </div>
      <TheCupTabSwitch :tabs="[translate('cup_6'), translate('cup_7')]" style="margin-bottom: 40px;" @switch="usePrizeView = !usePrizeView" home/>
      <ThePrizeCategory v-if="usePrizeView" />
      <TheTopTraders v-else />
    </div>
  </template>
</PageWithHeader>
</template>

<script lang="ts">
import ThePrizeCategory from '@/modules/weTradeCup/components/ThePrizeCategory.vue'
import TheTopTraders from '@/modules/weTradeCup/components/TheTopTraders.vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import TheCupTabSwitch from '@/modules/weTradeCup/components/TheCupTabSwitch.vue'
import { defineComponent, shallowRef } from 'vue'
import { translate } from '@/i18n'
import { keymap } from '@/config'
import { localGet, localRemove } from 'essential/store/localStore'
import { onBeforeRouteLeave } from 'vue-router'

export default defineComponent({
  name: 'WeTradeCup',
  components: { TheTopTraders, ThePrizeCategory, PageWithHeader, TheCupTabSwitch },
  props: {},
  setup () {
    const localTab = localGet(keymap.cup.mainTab)
    const usePrizeView = shallowRef(!Number(localTab))

    onBeforeRouteLeave((to) => {
      if (to.name !== 'symbol') {
        localRemove(keymap.cup.mainTab)
        localRemove(keymap.cup.subTab)
      }
    })

    return {
      usePrizeView,
      translate,
    }
  },
})
</script>

<style scoped>
.wt-cup {
  background: #230E8A;
  background-size: 100%;
  position: relative;
  padding-top: 76vw;
}
.time{
  background-image: url(/img/cup/wt/header@2x.png);
  background-size: 100%;
  height: 99.73vw;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}
.season{
  font-size: 18px;
  color: var(--color-white);
}
.date-l{
  height: 30px;
  width: 0;
  border-right: 16px solid #FF6000;
  border-top: 12px solid transparent;
  box-sizing: border-box;
  margin-right: -1px;
}
.date-m{
  height: 30px;
  line-height: 30px;
  background: #FF6000;
  color: var(--color-white);
  font-size: 20px;
  font-weight: bold;
  padding: 0 8px;
  border-top-left-radius: 2px;
  border-bottom-right-radius: 1px;
}
.date-r{
  height: 30px;
  width: 0;
  border-left: 16px solid #FF6000;
  border-bottom: 12px solid transparent;
  margin-left: -1px;
}
.t-r{
  border-radius: 12px;
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
  padding: 4px 12px;
  white-space: nowrap;
}
</style>
