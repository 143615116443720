/**
 * @author 阿佑[ayooooo@petalmail.com]
 * @date 2022/4/28 14:41
 * @description
 *   TheMasterPositions.vue of WeTrade
 */
<template>
<div class="br-lg no-of mt-8 mb-32">
  <div class="df-middle bg-background px-16" style="height: 48px">
    <div class="flex-1">
      <t path="cup_19">Positions</t> ({{master.orderNumbers || 0}})
    </div>
    <Pic :src="`cup/wt/alarm${isFollow ? '_off': ''}`" width="16" height="16" />
    <t path="" class="f-sm ml-4" :class="{'c-title': isFollow}" @click="toggleFollow">
      {{isFollow ? translate('cup_21') : translate('cup_20')}}
    </t>
  </div>
  <div class="px-16 bg-white">
    <div v-for="(item, index) in orders" :key="index" class="pb-24 d-f" :class="{'pt-24': index===0}">
      <Pic :src="item.icon" width="32" height="32" class="mr-8" style="border-radius: 50%;" />
      <div class="flex-1">
        <p class="c-black">{{item.contactCode}}</p>
        <p :class="item.buyType===2?'c-success':'c-danger'" >{{['','Sell','Buy'][item.buyType]}}@{{item.createPrice}}</p>
      </div>
      <RouterLink :to="`/symbol/${item.contactCode}`">
        <t as="div" path="cup_22" class="copy-btn br-lg">Copy</t>
      </RouterLink>
    </div>
    <div v-if="haveMore" class="f-xs c-tip pt-8 pb-16 t-center" @click="loadMore">
      <LoadingOr :loading="loading">
        <t path="cup_35">More</t> <Icon name="caret-down" sm />
      </LoadingOr>
    </div>
  </div>
</div>
</template>

<script lang="ts">
import Icon from '@/components/Icon.vue'
import Pic from '@/components/Pic.vue'
import { openDialog } from '@/components/popup/popup'
import useRequest from '@/hooks/useRequest'
import ToggleFollowDialog from '@/modules/weTradeCup/views/ToggleFollowDialog.vue'
import { Master, Order, readMasterOrders } from '@/modules/weTradeCup/weTradeCup.api'
import LoadingOr from '@/provider/LoadingOr.vue'
import { YesOrNo } from '@/types'
import { defineComponent, shallowRef, computed } from 'vue'
import { translate } from '@/i18n'
import state from '@/state'
import LimitDialogTemplate from '@/modules/membership/LimitDialogTemplate.vue'

export default defineComponent({
  name: 'TheMasterPositions',
  components: { LoadingOr, Icon, Pic },
  props: {
    master: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    const master = props.master as Master
    const haveMore = shallowRef(master.orderNumbers > 2)
    const orders = shallowRef<Order[]>(master.orderInfoModels)
    const isFollow = shallowRef(master.follow === YesOrNo.YES)

    const isPrime = computed(() => state.prime.member === YesOrNo.YES)

    const loaded = (resp: Master) => {
      haveMore.value = false
      orders.value = resp.orderInfoModels
    }

    const [load, loading] = useRequest(readMasterOrders)

    const toggleFollow = () => {
      if (!isPrime.value) {
        openDialog(LimitDialogTemplate, {
          i18nPath: 'cup_27',
        })
        return false
      }
      openDialog(ToggleFollowDialog, {
        isFollow: isFollow.value,
        masterId: master.userId,
        masterName: master.name,
        onConfirm () {
          isFollow.value = !isFollow.value
        },
      })
    }

    const loadMore = () => load({
      masterId: props.master.userId,
    }).then(loaded)

    return {
      orders,
      haveMore,
      loading,
      isFollow,
      toggleFollow,
      loadMore,
      translate,
    }
  },
})
</script>
<style scoped>
.copy-btn{
  padding: 0 12px;
  height: 32px;
  line-height: 32px;
  background: var(--color-primary);
  color: var(--color-white);
}
</style>
