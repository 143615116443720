/**
* @author 阿佑[ayooooo@petalmail.com]
* @date 2022/4/27 10:54
* @description
*   ThePrizeCategory.vue of WeTrade
*/
<template>
<div class="px-16" style="z-index: 1;">
  <div>
    <p class="cat-title f-xl c-white f-bold t-center">
      <t path="cup_8">WEEKLY</t>
      <span class="f-nm sub-title ml-8">
        <t path="cup_9">TOP 50</t>
      </span>
    </p>
  </div>
  <div class="prize p-16 mx-16">
    <div class="df-middle t-center">
      <div class="flex-1">
        <Pic src="cup/wt/gold" width="56" height="56" />
        <t as="p" path="" class="value">300g</t>
      </div>
      <div class="flex-1 divider dash">
        <Pic src="cup/wt/wallet" width="56" height="56" />
        <t as="p" path="" class="value">$13800</t>
      </div>
    </div>
    <div class="df-middle controls mt-16">
      <RouterLink to="/cup/wt/detail?type=1" class="flex-1">
        <t path="cup_10">Detail</t>
      </RouterLink>
      <RouterLink to="/cup/wt/detail?type=1&tab=1" class="flex-1 divider">
        <t path="cup_11">Results</t>
      </RouterLink>
    </div>
  </div>
</div>
<div class="wedge" style="height: 40px"></div>
<div class="px-16 right">
  <div class="t-right">
    <p class="cat-title f-xl c-white f-bold t-center" style="padding-left: 20px;">
      <t path="cup_12">MONTHLY</t>
      <span class="f-nm sub-title ml-8">
        <t path="cup_13">TOP 50</t>
      </span>
    </p>
  </div>
  <div class="prize p-16 mx-16">
    <div class="df-middle t-center">
      <div class="flex-1">
        <Pic src="cup/wt/gold" width="56" height="56" />
        <t as="p" path="" class="value">132g</t>
      </div>
      <div class="flex-1 divider dash">
        <Pic src="cup/wt/wallet" width="56" height="56" />
        <t as="p" path="" class="value">$14100</t>
      </div>
    </div>
    <div class="df-middle controls mt-16">
      <RouterLink to="/cup/wt/detail?type=2" class="flex-1">
        <t path="cup_10">Detail</t>
      </RouterLink>
      <RouterLink to="/cup/wt/detail?type=2&tab=1" class="flex-1 divider">
        <t path="cup_11">Results</t>
      </RouterLink>
    </div>
  </div>
</div>
<div class="wedge" style="height: 40px"></div>
<div class="px-16">
  <div>
    <p path="" class="cat-title f-xl c-white f-bold t-center">
      <t path="cup_14">SEASON</t>
      <span class="f-nm sub-title ml-8">
        <t path="cup_15">TOP 50</t>
      </span>
    </p>
  </div>
  <div class="prize p-16 mx-16">
    <div class="df-middle t-center">
      <div class="flex-1">
        <Pic src="cup/wt/gold" width="56" height="56" />
        <t as="p" path="" class="value">89g</t>
      </div>
      <div class="flex-1 divider dash">
        <Pic src="cup/wt/wallet" width="56" height="56" />
        <t as="p" path="" class="value">$10400</t>
      </div>
    </div>
    <div class="df-middle controls mt-16">
      <RouterLink to="/cup/wt/detail?type=3" class="flex-1">
        <t path="cup_10">Detail</t>
      </RouterLink>
      <RouterLink to="/cup/wt/detail?type=3&tab=1" class="flex-1 divider">
        <t path="cup_11">Results</t>
      </RouterLink>
    </div>
  </div>
</div>
<div class="wedge" style="height: 40px"></div>
</template>

<script lang="ts">
import Pic from '@/components/Pic.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ThePrizeCategory',
  components: { Pic },
  props: {},
  setup () {
    return {}
  },
})
</script>

<style scoped lang="scss">
.cat-title {
  position: relative;
  display: inline-block;
  width: 233px;
  line-height: 36px;
  font-style: italic;
  background: url(/img/cup/wt/rhombus_left@2x.png) left center no-repeat;
  background-size: 100%;

  .right & {
    margin-left: unset;
    background: url(/img/cup/wt/rhombus_right@2x.png) right center no-repeat;
    background-size: 100%;
  }
}

.sub-title {
  color: #ABC2FF;
}

.prize {
  margin-top: -8px;
  border-radius: 6px;
  background: #341B9D;
  background: linear-gradient(135deg, transparent 16px, #341B9D 0) top left,
    linear-gradient(-135deg, transparent 0px, #341B9D 0) top right,
    linear-gradient(-45deg, transparent 16px, #341B9D 0) bottom right,
    linear-gradient(45deg, transparent 0px, #341B9D 0) bottom left;
  background-size: 51% 51%;
  background-repeat: no-repeat;

  .right & {
    background: #341B9D;
    background: linear-gradient(135deg, transparent 0, #341B9D 0) top left,
      linear-gradient(-135deg, transparent 16px, #341B9D 0) top right,
      linear-gradient(-45deg, transparent 0, #341B9D 0) bottom right,
      linear-gradient(45deg, transparent 16px, #341B9D 0) bottom left;
    background-size: 51% 51%;
    background-repeat: no-repeat;
  }

  .value {
    font-weight: bold;
    color: #FFE100;
  }

  .controls {
    border-radius: 3px;
    line-height: 40px;
    text-align: center;
    color: var(--color-white);
    background: #5841D0;
  }

  .divider {
    position: relative;

    &::before {
      position: absolute;
      top: 20%;
      bottom: 20%;
      left: 0;
      border-left: 1px solid #6B51E2;
      content: '';
    }

    &.dash::before {
      border-left-style: dashed;
    }
  }
}
</style>
