/**
* @author 阿佑[ayooooo@petalmail.com]
* @date 2022/4/27 10:56
* @description
*   TheTopTraders.vue of WeTrade
*/
<template>
<div class="px-16">
  <div class="tab df-middle">
    <img src="/img/cup/wt/tab@2x.png" class="tab-img" ref="tabImg" alt="">
    <div class="t-item" :class="{'active': activeIndex===0}" @click="change(0)">
      <t path="cup_16">All</t>
    </div>
    <div class="t-item" :class="{'active': activeIndex===1}" @click="change(1)">
      <t path="cup_17">My followings</t>
    </div>
  </div>
  <div class="p-16" style="background: #432CAA;margin-top: -1px;min-height: 300px;">
    <Holder :content="traders">
      <Nullable :content="traders">
        <div v-for="item in traders" :key="item.rank + Math.random()" class="master">
          <div class="d-f">
            <Pic :src="item.avatar" fallback="/img/user/Avatar@2x.png" width="40" height="40" class="round avatar" />
            <div class="flex-1 c-white ml-12">
              <p class="f-bold f-nm">{{item.name || '--'}}</p>
              <p class="f-sm mt-4 df-middle">
                <span class="shadow df-middle mr-4">
                  <TheRankNumber :rank="item.rank" sm by-desc style="margin-right:2px;" /> {{item.rank}}
                </span>
                {{cupDateFormat(item.startDate)}}  -  {{cupDateFormat(item.endDate)}}
              </p>
            </div>
            <div>
              <ColorText :value="item.profitability" class="f-xxl f-bold">
                {{item.profitability / 100}}%
              </ColorText>
              <t as="p" path="cup_18" class="f-xs c-grey">Last 3 days</t>
            </div>
          </div>
          <TheMasterPositions :master="item" />
        </div>
      </Nullable>
    </Holder>
  </div>
</div>

<div v-if="!opened" class="prompt p-a df-middle c-white f-sm px-16" @click="prompt">
  <Pic src="cup/wt/coupon_5" width="40" height="40" />
  <t as="p" path="cup_34" class="flex-1 ml-8">
    You are on the Top list. Are you willing to disclose your positions?
  </t>
  <Icon name="caret-right" sm />
</div>
</template>

<script lang="ts">
import ColorText from '@/components/ColorText.vue'
import Nullable from '@/components/Nullable.vue'
import Holder from '@/provider/Holder.vue'
import Icon from '@/components/Icon.vue'
import Pic from '@/components/Pic.vue'
import TheMasterPositions from '@/modules/weTradeCup/components/TheMasterPositions.vue'
import TheRankNumber from '@/modules/weTradeCup/components/TheRankNumber.vue'
import { promptOpenOperation, cupDateFormat } from '@/modules/weTradeCup/weTradeCup'
import { Master, readMasters } from '@/modules/weTradeCup/weTradeCup.api'
import { defineComponent, onMounted, ref, shallowRef } from 'vue'
import { keymap } from '@/config'
import { localGet, localSet } from 'essential/store/localStore'

export default defineComponent({
  name: 'TheTopTraders',
  components: { Icon, TheMasterPositions, TheRankNumber, ColorText, Pic, Nullable, Holder },
  props: {},
  setup () {
    const traders = shallowRef<Master[] | null>(null)
    const activeIndex = shallowRef(0)
    const tabImg = ref<HTMLElement>()
    const opened = shallowRef(true)

    onMounted(() => {
      const localTab = localGet(keymap.cup.subTab)
      if (tabImg.value) {
        tabImg.value.style.transform = 'rotateY(' + Number(localTab) * 180 + 'deg)'
      }
      activeIndex.value = Number(localTab)
      read()
    })

    const prompt = promptOpenOperation(isPublic => opened.value = isPublic)

    const read = () => readMasters({ type: activeIndex.value }).then(resp => traders.value = resp)

    const change = (index: number) => {
      if (index === activeIndex.value) {
        return false
      }
      activeIndex.value = index
      localSet(keymap.cup.subTab, String(index))

      read()

      if (tabImg.value) {
        tabImg.value.style.transform = 'rotateY(' + index * 180 + 'deg)'
      }
    }

    return {
      traders,
      activeIndex,
      tabImg,
      opened,
      prompt,
      change,
      cupDateFormat,
    }
  },
})
</script>

<style scoped lang="scss">
.tab {
  height: 56px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
  position: relative;

  .tab-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 56px;
  }

  .t-item {
    flex: 1;
    text-align: center;
    height: 56px;
    line-height: 56px;
    font-size: 17px;
    font-weight: 400;
    color: #806FD6;
    z-index: 1;

    &.active {
      font-weight: bold;
      color: var(--color-white);
    }
  }
}

.avatar {
  border: 2px solid var(--color-white);
}

.shadow {
  padding: 1px 5px;
  border-radius: 10px;
  background: #230E8A;
}

.prompt {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparentize(#181D24, 0.1);
  height: 56px;
}
</style>
